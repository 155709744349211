@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url('fonts/OpenSans-Regular.eot');
    src: url('fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
    url('fonts/OpenSans-Regular.woff2') format('woff2'),
    url('fonts/OpenSans-Regular.woff') format('woff'),
    url('fonts/OpenSans-Regular.ttf') format('truetype'),
    url('fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url('fonts/OpenSans-Bold.eot');
    src: url('fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
    url('fonts/OpenSans-Bold.woff2') format('woff2'),
    url('fonts/OpenSans-Bold.woff') format('woff'),
    url('fonts/OpenSans-Bold.ttf') format('truetype'),
    url('fonts/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
}
html, #root {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Open Sans',-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-color: #f0f0f0;*/
    min-height: 100vh;
    height: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* UTILS */
.gridItem{padding: 0 8px}
.mepGridItem{margin: 8px 0 !important;}
.mepGridItem > a{height: 100%}
.dblock{display: block}
.dblockImp{display: block !important;}
.preWrap{white-space: pre-wrap}
.noEvents{pointer-events: none}
.nodeco{text-decoration: none !important;color: inherit !important;}
.bgTrans{background: transparent !important;}
.alignCenterImp{align-content: center !important;}
.masonryWrapper{
    max-width: 100% !important;
}
.MuiOutlinedInput-multiline {
    padding: 0 !important;
}
.MuiOutlinedInput-multiline textarea {
    padding: 18.5px 14px;
}
.sendFeedbackForm *:focus-visible{border-radius: 20px !important;}
.sendFeedbackForm *:focus{border-radius: 20px !important;}

@media (max-width: 767px) {
    .mepGridItem{margin: 0 !important;}
}
@media (max-width: 768px) {
    .masonryWrapper{
        max-width: 480px !important;
    }
}
@media (max-width: 1024px) {
    .homeCardList{
        padding-top: 10px;
    }
}
@media (max-width: 767px) {
    .homeCardList{
        padding-top: 0;
    }
    .xs_noevents{
        pointer-events: none;
    }
}
.masonryContainer .masonryItem{
    width: calc(50% - 8px);
}

@media (max-width: 768px) {
    .masonryContainer .masonryItem{
        width: auto;
        position: static !important;
    }
}

/* DETAILS */
.stickyTopics > div{
    position: sticky;
    top: 90px;
}
.updownList{
    padding: 0;
    margin: 0;
}

/* OVERRIDES */
.MuiPickersToolbarButton-toolbarBtn {
    width: 100% !important;
}
legend{padding: 0}
.MuiDialogActions-root.MuiDialogActions-spacing .MuiButton-root {
    min-width: 0px !important;
}
.MuiButton-root {
    text-transform: none !important;
    border-radius: 50px !important;
    min-width: 110px !important;
}
.MuiButton-sizeSmall{
    min-width: 64px !important;
}

.MuiOutlinedInput-root {
    border-radius: 30px !important;
}
.MuiOutlinedInput-multiline {
    border-radius: 15px !important;
}

.custom_datepicker >div{
    height: 40px;
    border: 1px solid #ccc;
    border-radius: 30px;
    margin-top: 0 !important;
}
.custom_datepicker input[type='text']{
    padding: 0 0 0 16px !important;
}
.custom_datepicker >div::before, .custom_datepicker >div::after{display: none}

.notFoundContent .MuiTypography-h2{
    color: #0d244a;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
} 
@media (max-width: 767px) {
    .custom_datepicker{
        width: 100%;
    }
}

.MuiBottomNavigationAction-wrapper {
    flex-direction: row !important;
}
.order1{
    order: 1;
}
.order2{
    order: 2;
}
.order3{
    order: 3;
}
@media (max-width: 1024px) {
    .sm_order1{
        order: 1;
    }
    .sm_order2{
        order: 2;
    }
    .sm_order3{
        order: 3;
    }
}
@media (max-width: 767px) {
    .xs_order1{
        order: 1;
    }
    .xs_order2{
        order: 2;
    }
    .xs_order3{
        order: 3;
    }
}

/*PULSE*/
.pulse {
    overflow: visible;
    position: relative
}
.pulse::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    -webkit-transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, -webkit-transform .3s;
    transition: opacity .3s, transform .3s;
    transition: opacity .3s, transform .3s, -webkit-transform .3s;
    -webkit-animation: pulse-animation 3s cubic-bezier(0.24, 0, 0.38, 1) forwards;
    animation: pulse-animation 3s cubic-bezier(0.24, 0, 0.38, 1) forwards;
    z-index: -1;
}
@-webkit-keyframes pulse-animation {
    0% {
        opacity: 0.5;
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
}

@keyframes pulse-animation {
    0% {
        opacity: 0.5;
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    50% {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.5);
        transform: scale(1.5)
    }
}

@media (max-width: 767px) {
    .accountDetails{
        margin-bottom: 10px !important;
    }
    .socialMediaIcons{
        margin: 0 !important;
    }
}

